import { Button, CircularProgress } from "@material-ui/core";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import OverlayContainer from "../../../../components/Atomic/OverlayContainer";
import { distanceBetween } from "../../../../core/geometricFunctions";
import { EditingArea, EditingAreaType } from "../../../../store/reducers/projectTreeReducer";
import { EditInfo } from "../../styles";

type Props = {
  editingArea: EditingArea;
  onCancel: () => void;
  setEditingPlan: (editingArea: EditingArea) => void;
  onFinish: () => void;
};

export default function (props: Props): JSX.Element {
  const [editionsEditingIndex, setEditionsEditingIndex] = useState(0);
  const [editions, setEditions] = useState<EditingArea[]>([props.editingArea]);

  useEffect(() => {
    const editionsCopy = [...editions];

    if (!_.isEqual(editionsCopy[editionsEditingIndex], props.editingArea)) {
      while (editionsCopy.length > editionsEditingIndex + 1) {
        editionsCopy.pop();
      }
      editionsCopy.push(props.editingArea);

      setEditions(editionsCopy);
      setEditionsEditingIndex(editionsCopy.length - 1);
    }
  }, [props.editingArea]);

  const onUndo = () => {
    if (editionsEditingIndex === 0) return;

    const selectedEdition = editions[editionsEditingIndex - 1];

    setEditionsEditingIndex(editionsEditingIndex - 1);
    props.setEditingPlan(selectedEdition);
  };

  const onRedo = () => {
    if (editionsEditingIndex === editions.length - 1) return;

    const selectedEdition = editions[editionsEditingIndex + 1];

    setEditionsEditingIndex(editionsEditingIndex + 1);
    props.setEditingPlan(selectedEdition);
  };

  const [saving, setSaving] = useState(false);

  const intl = useIntl();

  const fewWaypoints = getEditionHasFewWaypoints(props.editingArea);
  const nearbyWaypoints = getEditionHasNearbyWaypoints(props.editingArea);
  const hasFewVertices = getEditionHasFewVertices(props.editingArea);

  return (
    <OverlayContainer>
      <EditInfo height={100}>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            pointerEvents: "all",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              pointerEvents: "all",
            }}
          >
            {intl.formatMessage({ id: "map.editMode.manualRoute" })}

            <div
              style={{
                color: "red",
              }}
            >
              {(fewWaypoints || hasFewVertices) &&
                intl.formatMessage({ id: "map.editMode.error.theePointsMinimum" })}
              {nearbyWaypoints && intl.formatMessage({ id: "map.editMode.error.nearbyPoints" })}
            </div>
          </div>

          <Button
            disabled={saving}
            onClick={() => {
              props.onCancel();
            }}
            color="default"
            variant="contained"
          >
            {intl.formatMessage({ id: "action.cancel" })}
          </Button>
          <Button
            disabled={saving || editionsEditingIndex === 0}
            onClick={onUndo}
            color="secondary"
            variant="contained"
          >
            {intl.formatMessage({ id: "action.undo" })}
          </Button>
          <Button
            disabled={saving || editionsEditingIndex === editions.length - 1}
            onClick={onRedo}
            color="secondary"
            variant="contained"
          >
            {intl.formatMessage({ id: "action.redo" })}
          </Button>

          <Button
            disabled={saving || nearbyWaypoints || fewWaypoints || hasFewVertices}
            onClick={() => {
              setSaving(true);

              props.onFinish();
            }}
            color="primary"
            variant="contained"
          >
            {saving ? (
              <CircularProgress style={{ height: "24px", width: "24px", color: "white" }} />
            ) : (
              intl.formatMessage({ id: "action.finish" })
            )}
          </Button>
        </div>
      </EditInfo>
    </OverlayContainer>
  );
}

const getEditionHasFewWaypoints = (edition: EditingArea): boolean => {
  if (
    edition.type === EditingAreaType.EditingEverything ||
    edition.type === EditingAreaType.EditingPlanPoints
  ) {
    return edition.waypoints.length < 3;
  }

  return false;
};

const getEditionHasNearbyWaypoints = (edition: EditingArea): boolean => {
  if (
    edition.type === EditingAreaType.EditingEverything ||
    edition.type === EditingAreaType.EditingPlanPoints
  ) {
    const waypoints = edition.waypoints;

    if (waypoints.length === 1) return true;

    for (let i = 1; i < waypoints.length; i++) {
      const distance = distanceBetween(waypoints[i - 1].location, waypoints[i].location);
      if (distance < 1) return true;
    }
  }

  return false;
};

const getEditionHasFewVertices = (edition: EditingArea): boolean => {
  if (
    edition.type === EditingAreaType.CreatingProject ||
    edition.type === EditingAreaType.EditingPolygon ||
    edition.type === EditingAreaType.EditingEverything ||
    edition.type === EditingAreaType.DrawingNewArea
  ) {
    return edition.polygon.length < 3;
  }
  return false;
};
