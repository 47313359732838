import { TileLayer } from "react-leaflet";
import React from "react";

export default () => {
  return (
    <TileLayer
      attribution='&copy; <a href="https://cloud.google.com/maps-platform/terms">Google</a>'
      url={"http://{s}.google.com/vt/lyrs=m&x={x}&y={y}&z={z}"}
      subdomains={["mt0", "mt1", "mt2", "mt3"]}
      maxZoom={22.0}
    />
  );
};
