import { Location } from "biohub-model";
import React from "react";
import { Polyline } from "react-leaflet";
import WithPixelsPerMeters from "./with_pixels_per_meters";

export default (props: {
  mapScaleSource: "map" | "state";
  flightReleaseSegments: Location[][][];
  trackWidth: number;
}): JSX.Element => {
  return (
    <>
      {props.flightReleaseSegments.map((flightReleaseSegments) => (
        <>
          {flightReleaseSegments.map((releaserSegment) => (
            <WithPixelsPerMeters
              source={props.mapScaleSource}
              getChild={(pixelsPerMeter) => (
                <ShowFlightReleaseTracksForASegment
                  segment={releaserSegment}
                  trackWidth={props.trackWidth}
                  pixelsPerMeter={pixelsPerMeter}
                />
              )}
            />
          ))}
        </>
      ))}
    </>
  );
};

const ShowFlightReleaseTracksForASegment = (props: {
  segment: Location[];
  trackWidth: number;
  pixelsPerMeter: number;
}) => {
  const weight = props.pixelsPerMeter * props.trackWidth;

  return (
    <Polyline
      positions={props.segment}
      pathOptions={{
        color: "rgba(50, 205, 50, 128)",
        weight: weight,
      }}
    />
  );
};
