import { Box } from "@material-ui/core";
import React from "react";
import WeatherOverlay from "./WeatherOverlay";
import MapControlsOverlay from "./MapControlsOverlay";
import AreaInfosOverlay from "./AreaInfosOverlay";
import { Project, Area } from "biohub-model";

type Props = {
  onClickModalLegendInformation: () => void;
  widthScreenType: "small" | "medium" | "regular";
  heightScreenType: "small" | "medium" | "regular";
  mediumScreenWeatherOverlayMarginBottom: number;
  isExpandedProjectTree: boolean;
  overlayProjectInfos: (value: boolean) => void;
  isExpandedProjectInfosOverlay: boolean;
  onClickOpenedProjectAndAreaDialog: (project: Project, area: Area | undefined) => void;
};

type ScreenType = "small" | "medium" | "regular";

export default function (props: Props): JSX.Element {
  // We use two separate boxes, both with absolute positioning, for independently
  // positioning the items in them. Both let all pointer events through, occupy
  // the entire screen and are transparent.

  return (
    <div onClick={(e) => e.stopPropagation()}>
      <Box style={{ ...boxStyle, margin: 0 }}>
        <WeatherOverlay />
        <MapControlsOverlay modalLegendInformation={props.onClickModalLegendInformation} />
      </Box>
      <Box style={{ display: "flex", position: "absolute", bottom: 20 }}>
        <AreaInfosOverlay
          isExpandedProjectTree={props.isExpandedProjectTree}
          setExpanded={props.overlayProjectInfos}
          expanded={props.isExpandedProjectInfosOverlay}
          onClickOpenDialog={props.onClickOpenedProjectAndAreaDialog}
        />
      </Box>
    </div>
  );
}

const boxStyle: React.CSSProperties = {
  position: "absolute",
  display: "flex",
  justifyContent: "flex-end",
  flex: 1,
  alignItems: "flex-end",
  flexDirection: "column",
  zIndex: 20,
  backgroundColor: "#ffffff00", // transparent
  pointerEvents: "none",
  top: 0,
  left: 0,
  right: 5,
  bottom: 20,
};
