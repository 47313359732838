import React from "react";
import { SystemState } from "../../../../../store/reducers/systemReducer";
import { useSelector } from "react-redux";
import OpenedAreaPolygon from "../../components/opened_area_polygon";
import { EditingAreaType } from "../../../../../store/reducers/projectTreeReducer";
import EditingAreaPolygonPoints from "../../components/editing_area_polygon_points";
import { Location, ReleaserAction, Waypoint } from "biohub-model";
import HomePoint from "../../components/home_point";
import EditingAreaBorders from "../../components/editing_area_borders";
import PlannedAreaRoute from "../../components/planned_area_route";
import WaypointComponent from "../../components/waypoint";
import L from "leaflet";

export default (props: {
  onClickWaypoint: (waypointIndex: number) => void;
  onClickPolygon: (location: Location) => void;
  onClickPlannedPathLine: (previousPointIndex: number, location: Location) => void;
  onHomePointMovementStart: () => void;
  onVertexMovementStart: () => void;
  onWaypointMovementStart: () => void;
  onWaypointMoved: (index: number, location: Location) => void;
  onPolygonVertexMoved: (index: number, newLocation: Location) => void;
  onHomePointMoved: (newLocation: Location) => void;
  map: L.Map;
}): JSX.Element => {
  const isEditingArea = useSelector(
    (state: SystemState) => state.projectTree.editingArea !== undefined
  );

  if (!isEditingArea) return <></>;

  return (
    <>
      <EditingAreaPolygonFromState onClick={props.onClickPolygon} />
      <EditingAreaVertexesFromState
        onVertexMoved={props.onPolygonVertexMoved}
        onMovementStart={props.onVertexMovementStart}
      />
      <EditingAreaWaypointsFromState
        onClickWaypoint={props.onClickWaypoint}
        onClickPolyline={props.onClickPlannedPathLine}
        onWaypointMovementStart={props.onWaypointMovementStart}
        onWaypointMoved={props.onWaypointMoved}
        map={props.map}
      />
      <EditingAreaHomePoint
        onMoved={props.onHomePointMoved}
        onMovementStart={props.onHomePointMovementStart}
        map={props.map}
      />
    </>
  );
};

const EditingAreaPolygonFromState = (props: {
  onClick: (location: Location) => void;
}): JSX.Element => {
  const isEditingPolygon = useSelector((state: SystemState) => {
    const editingArea = state.projectTree.editingArea;
    if (editingArea === undefined) return false;

    switch (editingArea.type) {
      case EditingAreaType.EditingPlanPoints:
        return false;
      default:
        return true;
    }
  });

  const areaPolygon = useSelector((state: SystemState) => {
    const editingArea = state.projectTree.editingArea;

    if (editingArea === undefined) return undefined;

    switch (editingArea.type) {
      case EditingAreaType.EditingPlanPoints:
        return editingArea.area.planned.polygon;
      default:
        return editingArea.polygon;
    }
  });

  if (areaPolygon === undefined) return <></>;

  return (
    <OpenedAreaPolygon polygon={areaPolygon} isEditing={isEditingPolygon} onClick={props.onClick} />
  );
};

const EditingAreaVertexesFromState = (props: {
  onMovementStart: () => void;
  onVertexMoved: (index: number, newLocation: Location) => void;
}): JSX.Element => {
  const editingAreaVertexes = useSelector((state: SystemState) => {
    const editingArea = state.projectTree.editingArea;

    if (editingArea === undefined) return undefined;

    switch (editingArea.type) {
      case EditingAreaType.EditingPlanPoints:
        return undefined;
      default:
        return editingArea.polygon;
    }
  });

  return (
    <>
      {editingAreaVertexes && (
        <>
          <EditingAreaPolygonPoints
            points={editingAreaVertexes}
            onVertexMovementStart={props.onMovementStart}
            onVertexMoved={props.onVertexMoved}
          />
          <EditingAreaBorders points={editingAreaVertexes} />
        </>
      )}
    </>
  );
};

const EditingAreaHomePoint = (props: {
  onMovementStart: () => void;
  onMoved: (newLocation: Location) => void;
  map: L.Map;
}): JSX.Element => {
  const editingHomePointLocation = useSelector((state: SystemState) => {
    const editingArea = state.projectTree.editingArea;
    if (editingArea === undefined) return undefined;

    switch (editingArea.type) {
      case EditingAreaType.EditingPlanPoints:
      case EditingAreaType.EditingEverything:
        return editingArea.homePoint;
      default:
        return undefined;
    }
  });

  return (
    <HomePoint
      mapScaleSource="state"
      homePointLocation={editingHomePointLocation}
      draggable={true}
      onDragStart={props.onMovementStart}
      onDragEnd={props.onMoved}
      map={props.map}
    />
  );
};

const EditingAreaWaypointsFromState = (props: {
  onClickWaypoint: (waypointIndex: number) => void;
  onClickPolyline: (previousPointIndex: number, location: Location) => void;
  onWaypointMovementStart: () => void;
  onWaypointMoved: (index: number, location: Location) => void;
  map: L.Map;
}): JSX.Element => {
  const editingAreaWaypointsLocations = useSelector((state: SystemState) => {
    const editingArea = state.projectTree.editingArea;
    if (editingArea === undefined) return undefined;

    switch (editingArea.type) {
      case EditingAreaType.EditingPlanPoints:
      case EditingAreaType.EditingEverything:
        return editingArea.waypoints.map((waypoint) => waypoint.location);
      default:
        return undefined;
    }
  });

  if (editingAreaWaypointsLocations === undefined) return <></>;

  return (
    <>
      <PlannedAreaRoute
        mapScaleSource="state"
        waypointsLocations={editingAreaWaypointsLocations}
        showDistances={false}
        onClick={props.onClickPolyline}
        map={props.map}
      />

      {editingAreaWaypointsLocations.map((_, index) => (
        <EditingAreaWaypointFromState
          waypointIndex={index}
          onDragStart={props.onWaypointMovementStart}
          onDragEnd={(location) => props.onWaypointMoved(index, location)}
          onClick={() => props.onClickWaypoint(index)}
          map={props.map}
        />
      ))}
    </>
  );
};

const EditingAreaWaypointFromState = (props: {
  waypointIndex: number;
  onClick: () => void;
  onDragStart: () => void;
  onDragEnd: (newLocation: Location) => void;
  map: L.Map;
}) => {
  const mapRotation = useSelector((state: SystemState) => state.projectTree.mapState.rotation);

  const waypoint: (Waypoint & { activeLiberation: boolean }) | undefined = useSelector(
    (state: SystemState) => {
      const editingArea = state.projectTree.editingArea;
      if (editingArea === undefined) return undefined;

      let waypoints: Waypoint[] | undefined;

      switch (editingArea.type) {
        case EditingAreaType.EditingPlanPoints:
        case EditingAreaType.EditingEverything:
          waypoints = editingArea.waypoints;
          break;
        default:
          waypoints = undefined;
          break;
      }

      if (waypoints === undefined) return undefined;

      const waypoint = waypoints.find((_, index) => index === props.waypointIndex);
      if (waypoint === undefined) return undefined;

      let activeLiberation = false;

      for (let i = 0; i < waypoints.length && i <= props.waypointIndex; i++) {
        const waypoint = waypoints[i];

        let hasAnyReleasingChange = false;

        for (const [key, value] of Object.entries(waypoint.releaserActions)) {
          if (!hasAnyReleasingChange) {
            if (!activeLiberation && value.type === ReleaserAction.release) {
              hasAnyReleasingChange = true;
              activeLiberation = true;
            } else if (activeLiberation && value.type === ReleaserAction.stopRelease) {
              hasAnyReleasingChange = true;
              activeLiberation = false;
            }
          }
        }
      }

      return {
        ...waypoint,
        activeLiberation: activeLiberation,
      };
    }
  );

  if (waypoint === undefined) return <></>;

  return (
    <WaypointComponent
      mapScaleSource="state"
      isActiveLiberation={waypoint.activeLiberation}
      isSelected={false}
      waypointIndex={props.waypointIndex}
      waypointLocation={waypoint.location}
      waypointHeading={undefined}
      waypointHeight={undefined}
      homePointElevation={undefined}
      waypointElevation={undefined}
      mapRotation={mapRotation}
      onClick={props.onClick}
      map={props.map}
      draggable={true}
      onDragStart={props.onDragStart}
      onDragEnd={props.onDragEnd}
    />
  );
};
