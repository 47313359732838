import "react-leaflet";
import React, { useEffect } from "react";
import { MapContainer, useMapEvents } from "react-leaflet";
import { BaseMapProps } from "../BaseMap";
import LeafletMapController from "./MapController";
import { initialProjectTreeMapState } from "../../../store/reducers/projectTreeReducer";
import "leaflet/dist/leaflet.css";
import "./style.css";
import L from "leaflet";
import "leaflet-rotate";

export default (props: BaseMapProps): JSX.Element => {
  return (
    <MapContainer
      center={initialProjectTreeMapState.center}
      zoom={initialProjectTreeMapState.zoom}
      style={{
        height: "100%",
        width: "100%",
      }}
      zoomControl={false}
      minZoom={2.0}
      maxZoom={22.0}
      maxBounds={
        new L.LatLngBounds(
          {
            lat: -90,
            lng: -180,
          },
          {
            lat: 90,
            lng: 180,
          }
        )
      }
      rotate={true}
      rotateControl={false}
      zoomSnap={0.25}
      zoomDelta={0.25}
    >
      <Map {...props} />
    </MapContainer>
  );
};

function Map(props: BaseMapProps): JSX.Element {
  const fetchMapDigitalSize = () => {
    const pixelBounds = map.getPixelBounds();
    if (pixelBounds.max === undefined || pixelBounds.min === undefined) {
      return;
    }

    const height = Math.abs(pixelBounds.max.y - pixelBounds.min.y);
    const width = Math.abs(pixelBounds.max.x - pixelBounds.min.x);

    props.onMapDigitalSizeChanged(height, width);
  };

  const map = useMapEvents({
    click: (e) => {
      props.onClick({
        lat: e.latlng.lat,
        lng: e.latlng.lng,
      });
    },
    zoomend: () => {
      props.onZoomChanged(map.getZoom());
    },
    moveend: () => {
      props.onCurrentCenterChanged(map.getCenter());
    },
    resize: () => {
      const mapBounds = map.getBounds();
      props.onMapBoundsChanged({
        north: mapBounds.getNorth(),
        east: mapBounds.getEast(),
        south: mapBounds.getSouth(),
        west: mapBounds.getWest(),
      });
      fetchMapDigitalSize();
    },
  });

  useEffect(() => {
    props.onInitialized(new LeafletMapController(map));

    fetchMapDigitalSize();
  }, []);

  return <>{props.getChildren !== undefined && props.getChildren(map)}</>;
}
