import {
  Box,
  Button,
  ButtonGroup,
  CircularProgress,
  createMuiTheme,
  Grid,
  makeStyles,
  MuiThemeProvider,
  Paper,
  TextField,
} from "@material-ui/core";
import { MyLocation, ZoomIn, ZoomOut } from "@material-ui/icons";
import React, { useEffect, useState } from "react";

import Autocomplete from "@material-ui/lab/Autocomplete";
import { GoogleMapsSearchInfo, searchPlaces } from "../../../../../services/SearchPlaces";
import { useIntl } from "react-intl";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import SearchImage from "../../../../../assets/icon/icon_search_thick_without_circle.svg";
import { useDispatch, useSelector } from "react-redux";
import SearchField from "../../../../../components/Atomic/Inputs/SearchField";
import {
  goToUserLocationProcedures,
  moveToCoordinates,
  setUserLocation,
  zoomIn,
  zoomOut,
} from "../../../../../store/actions/projectTreeActions";
import { SystemState } from "../../../../../store/reducers/systemReducer";
import { distanceBetween } from "../../../../../core/geometricFunctions";

type Props = {
  openSearch: boolean;
  setOpenSearch: (value: boolean) => void;
};

export default function MapControls(props: Props): JSX.Element {
  const dispatch = useDispatch();

  const [loadingGeolocation, setLoadingGeolocation] = useState<boolean>(false);

  const { openSearch, setOpenSearch } = props;

  const classes = useStyles();

  //terms
  const intl = useIntl();
  const termSearchAddress = intl.formatMessage({ id: "placeholder.search" });

  const userPosition = useSelector((state: SystemState) => state.projectTree.userLocation);

  const mapCenter = useSelector((state: SystemState) => state.projectTree.mapState.center);

  useEffect(() => {
    if (!loadingGeolocation) return;

    if (userPosition === null || userPosition === undefined) return;

    const latitudeDiff = userPosition.lat - mapCenter.lat;
    const longitudeDiff = userPosition.lng - mapCenter.lng;

    const diff = Math.abs(latitudeDiff) + Math.abs(longitudeDiff);
    if (diff > 0.00001) return;

    const userPositionCenterDistance = distanceBetween(userPosition, mapCenter);
    if (userPositionCenterDistance < 1) {
      setLoadingGeolocation(false);
    }
  }, [loadingGeolocation, mapCenter, userPosition]);

  return (
    <ButtonGroup
      variant="outlined"
      color="primary"
      style={{ display: "flex", justifyContent: "center", height: "26px", margin: "3px" }}
    >
      <Button
        disabled={loadingGeolocation}
        style={{
          borderColor: "white",
          width: "22px",
          minWidth: "22px",
          color: "white",
          opacity: 1,
        }}
        onClick={async (_) => {
          setLoadingGeolocation(true);
          dispatch(goToUserLocationProcedures(goToLocationZoom));
        }}
      >
        {loadingGeolocation ? (
          <CircularProgress
            style={{ display: "flex", height: "20px", width: "20px", color: "white" }}
          />
        ) : (
          <MyLocation />
        )}
      </Button>
      <Button
        style={{
          borderColor: "white",
          width: "22px",
          minWidth: "22px",
          color: "white",
        }}
        onClick={(_) => {
          setOpenSearch(!openSearch);
        }}
      >
        <img
          src={SearchImage}
          style={{ position: "absolute", height: 20, width: 20, filter: "invert(100%)" }}
        />
      </Button>
      <Button
        style={{
          borderColor: "white",
          width: "22px",
          minWidth: "22px",
          color: "white",
        }}
        onClick={(_) => {
          dispatch(zoomIn());
        }}
      >
        <ZoomIn />
      </Button>
      <Button
        style={{
          borderColor: "white",
          width: "22px",
          minWidth: "22px",
          color: "white",
        }}
        onClick={(_) => {
          dispatch(zoomOut());
        }}
      >
        <ZoomOut />
      </Button>
      {openSearch && (
        <div
          style={{
            position: "absolute",
            right: 0,
            bottom: 60,
          }}
        >
          <Box
            width={400}
            style={{
              paddingTop: 2,
              paddingBottom: 4,
              paddingLeft: 4,
              paddingRight: 4,
            }}
          >
            <SearchField<GoogleMapsSearchInfo>
              label={termSearchAddress}
              popperPlacementType={"top"}
              formatOption={(option) => option.formattedAddress}
              onSelect={(option) => {
                dispatch(moveToCoordinates(option.location, goToLocationZoom));
              }}
              onCloseEdition={() => {}}
              renderOption={(option) => {
                return (
                  <Grid container alignItems="center" className={classes.grid}>
                    <Grid item>
                      <LocationOnIcon className={classes.icon} />
                    </Grid>
                    <Grid item xs>
                      {option.formattedAddress}
                    </Grid>
                  </Grid>
                );
              }}
              searchOption={async (input: string): Promise<Array<GoogleMapsSearchInfo>> => {
                const result = await searchPlaces(input);
                if (result.success) {
                  return [result.data];
                }
                return [];
              }}
            />
          </Box>
        </div>
      )}
    </ButtonGroup>
  );
}

// Solution to change the text field color
const theme = createMuiTheme({
  palette: {
    secondary: {
      main: "#ef7622",
    },
  },
});

const useStyles = makeStyles((theme) => ({
  icon: {
    color: "white",
    marginRight: theme.spacing(2),
  },
  grid: {
    color: "white",
    fontSize: "small",
  },
}));

function SearchComponent(props: {
  onSelect: (address: GoogleMapsSearchInfo) => void;
  onCloseEdition: () => void;
}) {
  const classes = useStyles();
  const [value, setValue] = React.useState<GoogleMapsSearchInfo | undefined>(undefined);
  const [inputValue, setInputValue] = React.useState<string>("");
  const [options, setOptions] = React.useState<Array<GoogleMapsSearchInfo>>([]);

  //terms
  const intl = useIntl();
  const termSearchAddress = intl.formatMessage({ id: "map.search" });

  return (
    <Autocomplete
      id="google-map-demo"
      getOptionLabel={(option) => option.formattedAddress}
      filterOptions={(x) => x}
      options={options}
      autoComplete
      noOptionsText={""}
      includeInputInList
      filterSelectedOptions
      value={value}
      inputValue={inputValue}
      onChange={(event, newValue) => {
        if (newValue !== null) {
          setValue(newValue);
          props.onSelect(newValue);
        }
      }}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
        if (newInputValue.length > 0) {
          searchPlaces(newInputValue).then((result) => {
            if (result.success) {
              setOptions([result.data]);
            }
          });
        } else {
          setOptions([]);
        }
      }}
      onBlur={props.onCloseEdition}
      PaperComponent={({ children }) => (
        <div style={{ position: "relative", paddingBottom: 15 }}>
          {options.length > 0 && (
            <Paper style={{ background: "var(--overlay-84)" }}>{children}</Paper>
          )}
        </div>
      )}
      renderInput={(params) => (
        <MuiThemeProvider theme={theme}>
          <TextField
            autoFocus
            label={termSearchAddress}
            variant="outlined"
            {...params}
            inputProps={{
              ...params.inputProps,
              style: { color: "white" },
            }}
            InputLabelProps={{
              style: { color: "white" },
            }}
            // color="white"
          />
        </MuiThemeProvider>
      )}
      renderOption={(option) => {
        return (
          <Grid container alignItems="center" className={classes.grid}>
            <Grid item>
              <LocationOnIcon className={classes.icon} />
            </Grid>
            <Grid item xs>
              {option.formattedAddress}
            </Grid>
          </Grid>
        );
      }}
    />
  );
}

const goToLocationZoom = 17;
