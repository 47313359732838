import React from "react";
import AreaFromState from "./area_from_state";
import { useSelector } from "react-redux";
import { SystemState } from "../../../../../store/reducers/systemReducer";
import L from "leaflet";
import RenderInsideBounds from "../../components/render_inside_bounds";
import { BoundingBox } from "biohub-model";
import { isProjectAsSummary } from "./projects_and_area_cluster_pattern";

export default (props: {
  projectId: string;
  onClickWaypoint: (areaId: string, waypointIndex: number) => void;
  moveToBoundingBox: (boundingBox: BoundingBox) => void;
  onClickNotSelectedArea: (areaId: string) => void;
  map: L.Map;
}): JSX.Element => {
  const isVisible = useSelector((state: SystemState) => {
    const project = state.projectTree.projectList?.find(
      (project) => project.id === props.projectId
    );
    if (project === undefined) return undefined;

    return project.visible;
  });

  if (isVisible === undefined || !isVisible) return <></>;

  return <ProjectAccordingBoundingBox {...props} />;
};

const ProjectAccordingBoundingBox = (props: {
  projectId: string;
  onClickWaypoint: (areaId: string, waypointIndex: number) => void;
  moveToBoundingBox: (boundingBox: BoundingBox) => void;
  onClickNotSelectedArea: (areaId: string) => void;
  map: L.Map;
}): JSX.Element => {
  const pixelsPerMeter = useSelector((state: SystemState) => {
    const mapState = state.projectTree.mapState;

    return mapState.visibleRegionDiagonalInPixels / mapState.boundingBoxDiagonalSize;
  });

  const projectBoundingBox = useSelector((state: SystemState) => {
    const project = state.projectTree.projectList?.find(
      (project) => project.id === props.projectId
    );
    if (project === undefined) return undefined;

    return {
      bounds: project.boundingBox,
      diagonalSizeInMeters: project.boundingBoxDiagonalSize,
    };
  });

  if (projectBoundingBox === undefined || projectBoundingBox.bounds === null) return <></>;

  if (isProjectAsSummary(projectBoundingBox.diagonalSizeInMeters * pixelsPerMeter)) return <></>;

  return (
    <RenderInsideBounds
      mapBoundsSource="state"
      elementBounds={projectBoundingBox.bounds}
      children={<ProjectSubAreas {...props} />}
    />
  );
};

const ProjectSubAreas = (props: {
  projectId: string;
  onClickWaypoint: (areaId: string, waypointIndex: number) => void;
  moveToBoundingBox: (boundingBox: BoundingBox) => void;
  onClickNotSelectedArea: (areaId: string) => void;
  map: L.Map;
}): JSX.Element => {
  const areasIds =
    useSelector((state: SystemState) =>
      state.projectTree.projectList
        ?.find((project) => project.id === props.projectId)
        ?.areas?.map((area) => area.id)
    ) ?? [];

  return (
    <>
      {areasIds.map((areaId) => (
        <AreaFromState
          projectId={props.projectId}
          areaId={areaId}
          onClickNotSelectedArea={() => props.onClickNotSelectedArea(areaId)}
          onClickWaypoint={(waypointIndex) => props.onClickWaypoint(areaId, waypointIndex)}
          moveToBoundingBox={props.moveToBoundingBox}
          map={props.map}
        />
      ))}
    </>
  );
};
