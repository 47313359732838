import { Location } from "biohub-model";
import React, { useEffect, useState } from "react";
import { Polyline } from "react-leaflet";
import L from "leaflet";

export default (props: { flowRoute: Location[] }): JSX.Element => {
  const [lineBorder, setLineBorder] = useState<L.Polyline>();
  const [line, setLine] = useState<L.Polyline>();

  useEffect(() => {
    if (line !== undefined && lineBorder !== undefined) {
      lineBorder.bringToFront();
      line.bringToFront();
    }
  }, [lineBorder, line]);

  return (
    <>
      {/* Flight route border */}
      <Polyline
        ref={(polyline) => {
          if (polyline !== null) setLineBorder(polyline);
        }}
        positions={props.flowRoute}
        pathOptions={{
          color: "rgba(65, 165, 245, 255)",
          weight: 5,
        }}
      />
      {/* Flight route */}
      <Polyline
        ref={(polyline) => {
          if (polyline !== null) setLine(polyline);
        }}
        positions={props.flowRoute}
        pathOptions={{
          color: "rgba(21, 101, 192, 255)",
          weight: 2,
        }}
      />
    </>
  );
};
