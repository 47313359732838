import React from "react";
import { SystemState } from "../../../../../store/reducers/systemReducer";
import {
  AreaInProjectTree,
  EditingAreaType,
  ProjectInProjectTree,
} from "../../../../../store/reducers/projectTreeReducer";
import { useSelector } from "react-redux";
import NotOpenedAreaPolygon from "../../components/not_opened_area_polygon";
import OpenedAreaPolygon from "../../components/opened_area_polygon";
import PlannedAreaRoute from "../../components/planned_area_route";
import L from "leaflet";
import { BoundingBox, PlannedArea, ReleaserAction, Waypoint } from "biohub-model";
import WaypointComponent from "../../components/waypoint";
import HomePoint from "../../components/home_point";
import RenderInsideBounds from "../../components/render_inside_bounds";
import { isAreaAsSummary } from "./projects_and_area_cluster_pattern";

export default (props: {
  projectId: string;
  areaId: string;
  onClickNotSelectedArea: () => void;
  moveToBoundingBox: (boundingBox: BoundingBox) => void;
  onClickWaypoint: (waypointIndex: number) => void;
  map: L.Map;
}): JSX.Element => {
  return <AreaRender {...props} />;
};

const AreaRender = (props: {
  projectId: string;
  areaId: string;
  onClickWaypoint: (waypointIndex: number) => void;
  moveToBoundingBox: (boundingBox: BoundingBox) => void;
  onClickNotSelectedArea: () => void;
  map: L.Map;
}): JSX.Element => {
  const isVisible = useSelector(
    (state: SystemState) => getAreaFromState(state, props.projectId, props.areaId)?.visible
  );

  if (isVisible === undefined || !isVisible) return <></>;

  return <AreaRenderConsideringBoundingBox {...props} />;
};

const AreaRenderConsideringBoundingBox = (props: {
  projectId: string;
  areaId: string;
  onClickWaypoint: (waypointIndex: number) => void;
  moveToBoundingBox: (boundingBox: BoundingBox) => void;
  onClickNotSelectedArea: () => void;
  map: L.Map;
}): JSX.Element => {
  const pixelsPerMeter = useSelector((state: SystemState) => {
    const mapState = state.projectTree.mapState;

    return mapState.visibleRegionDiagonalInPixels / mapState.boundingBoxDiagonalSize;
  });

  const areaBoundingBox = useSelector((state: SystemState) => {
    const projectTreeState = state.projectTree;
    const selectedProjectId = projectTreeState.selectedProjectId;
    const project = projectTreeState.projectList?.find((project) => project.id === props.projectId);
    const selectedAreaId = project?.selectedAreaId;
    const area = project?.areas?.find((area) => area.id === props.areaId);
    if (area === undefined) return undefined;

    if (
      selectedProjectId === area.projectId &&
      selectedAreaId === area.id &&
      area.viewingFlightPlan !== undefined
    ) {
      return {
        bounds: area.viewingFlightPlan.boundingBox,
        diagonalSizeInMeters: area.viewingFlightPlan.boundingBoxDiagonalSize,
      };
    }

    return {
      bounds: area.boundingBox,
      diagonalSizeInMeters: area.boundingBoxDiagonalSize,
    };
  });

  if (areaBoundingBox === undefined) return <></>;
  if (isAreaAsSummary(areaBoundingBox.diagonalSizeInMeters * pixelsPerMeter)) return <></>;

  return (
    <RenderInsideBounds
      mapBoundsSource="state"
      elementBounds={areaBoundingBox.bounds}
      children={<SelectedOrNotAreaConsideringEditingArea {...props} />}
    />
  );
};

const getProjectFromState = (
  state: SystemState,
  projectId: string
): ProjectInProjectTree | undefined => {
  return state.projectTree.projectList?.find((project) => project.id === projectId);
};

const getAreaFromState = (
  state: SystemState,
  projectId: string,
  areaId: string
): AreaInProjectTree | undefined => {
  return getProjectFromState(state, projectId)?.areas?.find((area) => area.id === areaId);
};

const getPlannedAreaFromState = (
  state: SystemState,
  projectId: string,
  areaId: string
): PlannedArea | undefined => {
  const project = getProjectFromState(state, projectId);
  const selectedProjectId = state.projectTree.selectedProjectId;
  const selectedAreaId = project?.selectedAreaId;

  const area = project?.areas?.find((area) => area.id === areaId);

  if (selectedProjectId === projectId && selectedAreaId === selectedAreaId) {
    return area?.viewingFlightPlan ?? area?.planned;
  }

  return area?.planned;
};

const SelectedOrNotAreaConsideringEditingArea = (props: {
  projectId: string;
  areaId: string;
  onClickWaypoint: (waypointIndex: number) => void;
  onClickNotSelectedArea: () => void;
  map: L.Map;
}): JSX.Element => {
  const isAreaInEdition = useSelector((state: SystemState) => {
    const editingArea = state.projectTree.editingArea;

    if (editingArea === undefined) return false;
    switch (editingArea.type) {
      case EditingAreaType.CreatingProject:
      case EditingAreaType.DrawingNewArea:
        return false;
      default:
        return editingArea.area.id === props.areaId;
    }
  });

  if (isAreaInEdition) return <></>;

  return <SelectedOrNotArea {...props} />;
};

const SelectedOrNotArea = (props: {
  projectId: string;
  areaId: string;
  onClickWaypoint: (waypointIndex: number) => void;
  onClickNotSelectedArea: () => void;
  map: L.Map;
}): JSX.Element => {
  const isSelected = useSelector(
    (state: SystemState) =>
      state.projectTree.selectedProjectId === props.projectId &&
      getProjectFromState(state, props.projectId)?.selectedAreaId === props.areaId
  );

  if (isSelected === undefined) return <></>;

  return (
    <>
      <AreaPolygonFromState {...props} isSelected={isSelected} />
      {isSelected && (
        <>
          <PlannedAreaRouteFromState {...props} />
          <AreaWaypointsFromState {...props} />
          <AreaHomePointFromState {...props} />
        </>
      )}
    </>
  );
};

const AreaPolygonFromState = (props: {
  projectId: string;
  areaId: string;
  isSelected: boolean;
  onClickNotSelectedArea: () => void;
}): JSX.Element => {
  const polygon = useSelector(
    (state: SystemState) => getPlannedAreaFromState(state, props.projectId, props.areaId)?.polygon
  );

  if (polygon === undefined) return <></>;

  if (props.isSelected) return <OpenedAreaPolygon polygon={polygon} />;
  else return <NotOpenedAreaPolygon polygon={polygon} onClick={props.onClickNotSelectedArea} />;
};

const PlannedAreaRouteFromState = (props: {
  projectId: string;
  areaId: string;
  map: L.Map;
}): JSX.Element => {
  const waypointsLocations = useSelector((state: SystemState) =>
    getPlannedAreaFromState(state, props.projectId, props.areaId)?.route?.waypoints.map(
      (waypoint) => waypoint.location
    )
  );

  if (waypointsLocations === undefined) return <></>;

  return (
    <PlannedAreaRoute
      mapScaleSource="state"
      waypointsLocations={waypointsLocations}
      map={props.map}
    />
  );
};

const getWaypointFromState = (
  state: SystemState,
  projectId: string,
  areaId: string,
  waypointIndex: number
): Waypoint | undefined => {
  return getPlannedAreaFromState(state, projectId, areaId)?.route?.waypoints.find(
    (_, index) => index === waypointIndex
  );
};

const AreaWaypointsFromState = (props: {
  projectId: string;
  areaId: string;
  onClickWaypoint: (waypointIndex: number) => void;
  map: L.Map;
}): JSX.Element => {
  const waypointsIndexes =
    useSelector((state: SystemState) =>
      getPlannedAreaFromState(state, props.projectId, props.areaId)?.route?.waypoints.map(
        (_, index) => index
      )
    ) ?? [];

  return (
    <>
      {waypointsIndexes.map((waypointIndex) => (
        <AreaWaypointFromState
          {...props}
          waypointIndex={waypointIndex}
          onClickWaypoint={() => props.onClickWaypoint(waypointIndex)}
        />
      ))}
    </>
  );
};

const AreaWaypointFromState = (props: {
  projectId: string;
  areaId: string;
  waypointIndex: number;
  onClickWaypoint: () => void;
  map: L.Map;
}): JSX.Element => {
  const mapRotation = useSelector((state: SystemState) => state.projectTree.mapState.rotation);
  const waypoint = useSelector((state: SystemState) =>
    getWaypointFromState(state, props.projectId, props.areaId, props.waypointIndex)
  );
  const isSelected = useSelector(
    (state: SystemState) =>
      state.projectTree.selectedWaypoints?.waypointIndexes.find(
        (index) => index === props.waypointIndex
      ) !== undefined
  );
  const isActiveLiberation = useSelector((state: SystemState) => {
    const area = getAreaFromState(state, props.projectId, props.areaId);
    if (area === undefined) return false;

    const waypoints = area.planned.route?.waypoints ?? [];

    let activeLiberation = false;

    for (let i = 0; i < waypoints.length && i <= props.waypointIndex; i++) {
      const waypoint = waypoints[i];

      let hasAnyReleasingChange = false;

      for (const [key, value] of Object.entries(waypoint.releaserActions)) {
        if (!hasAnyReleasingChange) {
          if (!activeLiberation && value.type === ReleaserAction.release) {
            hasAnyReleasingChange = true;
            activeLiberation = true;
          } else if (activeLiberation && value.type === ReleaserAction.stopRelease) {
            hasAnyReleasingChange = true;
            activeLiberation = false;
          }
        }
      }
    }

    return activeLiberation;
  });

  const homePointElevation = useSelector(
    (state: SystemState) =>
      getPlannedAreaFromState(state, props.projectId, props.areaId)?.homePointElevation
  );

  if (waypoint === undefined) return <></>;

  return (
    <WaypointComponent
      mapScaleSource="state"
      isActiveLiberation={isActiveLiberation}
      isSelected={isSelected}
      waypointIndex={props.waypointIndex}
      waypointLocation={waypoint.location}
      waypointHeading={waypoint.orientation}
      waypointHeight={waypoint.height}
      homePointElevation={homePointElevation}
      waypointElevation={waypoint.elevation}
      mapRotation={mapRotation}
      onClick={props.onClickWaypoint}
      map={props.map}
    />
  );
};

const AreaHomePointFromState = (props: {
  projectId: string;
  areaId: string;
  map: L.Map;
}): JSX.Element => {
  const homePointLocation = useSelector(
    (state: SystemState) => getPlannedAreaFromState(state, props.projectId, props.areaId)?.homePoint
  );
  const homePointElevation = useSelector(
    (state: SystemState) =>
      getPlannedAreaFromState(state, props.projectId, props.areaId)?.homePointElevation
  );

  return (
    <HomePoint
      mapScaleSource="state"
      homePointLocation={homePointLocation}
      homePointElevation={homePointElevation}
      map={props.map}
    />
  );
};
