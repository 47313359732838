import { useSelector } from "react-redux";
import FlightRoute from "../../components/flight_route";
import FlightReleaseTracks from "../../components/flight_release_tracks";
import React from "react";
import { SystemState } from "../../../../../store/reducers/systemReducer";
import { FlightInProjectTree } from "../../../../../store/reducers/projectTreeReducer";

export default (props: { projectId: string; areaId: string; flightId: string }): JSX.Element => {
  const isVisible = useSelector(
    (state: SystemState) =>
      getFlightFromState(state, props.projectId, props.areaId, props.flightId)?.visible
  );

  if (isVisible === undefined || !isVisible) return <></>;

  return (
    <>
      <FlightReleaseTracksFromState {...props} />
      <FlightRouteFromState {...props} />
    </>
  );
};

const getFlightFromState = (
  state: SystemState,
  projectId: string,
  areaId: string,
  flightId: string
): FlightInProjectTree | undefined => {
  const project = state.projectTree.projectList?.find((project) => project.id === projectId);
  if (project === undefined) return undefined;

  const area = project.areas?.find((area) => area.id === areaId);
  if (area === undefined) return undefined;

  return area.flightList?.find((flight) => flight.id === flightId);
};

const FlightRouteFromState = (props: {
  projectId: string;
  areaId: string;
  flightId: string;
}): JSX.Element => {
  const flowRoute = useSelector(
    (state: SystemState) =>
      getFlightFromState(state, props.projectId, props.areaId, props.flightId)?.realData
        ?.completeRoute
  );

  if (flowRoute === undefined) return <></>;

  return <FlightRoute flowRoute={flowRoute} />;
};

const FlightReleaseTracksFromState = (props: {
  projectId: string;
  areaId: string;
  flightId: string;
}): JSX.Element => {
  const trackWidth = useSelector(
    (state: SystemState) =>
      getFlightFromState(state, props.projectId, props.areaId, props.flightId)
        ?.flightEnvironmentSnapshot.trackWidth
  );
  const flightReleaseSegments = useSelector((state: SystemState) =>
    getFlightFromState(state, props.projectId, props.areaId, props.flightId)?.realData?.logData.map(
      (log) => log.releaseRoute
    )
  );

  if (trackWidth === undefined || flightReleaseSegments === undefined) return <></>;

  return (
    <FlightReleaseTracks
      mapScaleSource="state"
      trackWidth={trackWidth}
      flightReleaseSegments={flightReleaseSegments}
    />
  );
};
