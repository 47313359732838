import { BoundingBox, Location } from "biohub-model";
import BaseMapController from "../BaseMapController";
import L from "leaflet";

const zoomInOutBaseStep = 0.5;

class LeafletMapController implements BaseMapController {
  constructor(mapRef: L.Map) {
    this._mapRef = mapRef;
  }

  _mapRef: L.Map;

  async moveTo(coordinate: Location, zoomLevel?: number): Promise<void> {
    let effectiveZoom = zoomLevel;
    if (effectiveZoom !== undefined) {
      const maxZoomLevel = this._mapRef.getMaxZoom();
      if (effectiveZoom > maxZoomLevel) {
        effectiveZoom = maxZoomLevel;
      }

      const minZoomLevel = this._mapRef.getMinZoom();
      if (effectiveZoom < minZoomLevel) {
        effectiveZoom = minZoomLevel;
      }
    }

    this._mapRef.flyTo(coordinate, effectiveZoom);
  }

  async moveToBoundingBox(box: BoundingBox): Promise<void> {
    this._mapRef.fitBounds(
      new L.LatLngBounds(
        {
          lat: box.south,
          lng: box.west,
        },
        {
          lat: box.north,
          lng: box.east,
        }
      )
    );
  }

  async zoomIn(steps?: number): Promise<void> {
    const _steps: number = steps === undefined ? zoomInOutBaseStep : steps;
    this._mapRef.zoomIn(_steps);
  }

  async zoomOut(steps?: number): Promise<void> {
    const _steps: number = steps === undefined ? zoomInOutBaseStep : steps;
    this._mapRef.zoomOut(_steps);
  }

  async getCenter(): Promise<Location> {
    const center = this._mapRef.getCenter();
    return { lat: center.lat, lng: center.lng };
  }

  async getMapBounds(): Promise<BoundingBox> {
    const bounds = this._mapRef.getBounds();
    const southWestBounds = bounds.getSouthWest();
    const northEastBounds = bounds.getNorthEast();

    return {
      north: northEastBounds.lat,
      south: southWestBounds.lat,
      east: northEastBounds.lng,
      west: southWestBounds.lng,
    };
  }

  async getZoom(): Promise<number> {
    return this._mapRef.getZoom();
  }

  async getRotation(): Promise<number> {
    return this._mapRef.getBearing();
  }

  async setRotation(rotation: number): Promise<void> {
    this._mapRef.setBearing(rotation);
  }
}

export default LeafletMapController;
