import { Location } from "biohub-model";
import React from "react";
import { Polygon } from "react-leaflet";

export default (props: { polygon: Location[]; onClick: () => void }): JSX.Element => {
  return (
    <Polygon
      ref={(polygon) => polygon?.bringToBack()}
      positions={props.polygon}
      pathOptions={{
        color: "rgba(239, 83, 80, 102)",
        fillColor: "rgba(239, 83, 80, 102)",
      }}
      eventHandlers={{
        click: (_) => props.onClick(),
      }}
    />
  );
};
