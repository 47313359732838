import React from "react";
import { useSelector } from "react-redux";
import { SystemState } from "../../../../../store/reducers/systemReducer";
import Cluster from "../../components/cluster";
import {
  centerOfBoundingBox,
  couldMapElementBeVisibleAccordingMapBounds,
} from "../../../../../core/geometricFunctions";
import Marker from "../../components/marker";
import L from "leaflet";
import { BoundingBox, Location } from "biohub-model";
import { Tooltip } from "react-leaflet";
import {
  areasClusterSize,
  areaSummarySize,
  isAreaAsSummary,
  isProjectAsSummary,
  projectSummarySize,
  projectsClusterSize,
} from "./projects_and_area_cluster_pattern";
import { AreaInProjectTree } from "../../../../../store/reducers/projectTreeReducer";

export default (props: { moveToBoundingBox: (boundingBox: BoundingBox) => void }): JSX.Element => {
  const projectsInfo = useSelector((state: SystemState) => {
    const projectTreeState = state.projectTree;
    const projectList = projectTreeState.projectList ?? [];

    const mapBounds = projectTreeState.mapState.bounds;

    const visibleProjects = projectList.filter(
      (project) =>
        project.visible &&
        project.boundingBox !== null &&
        couldMapElementBeVisibleAccordingMapBounds(project.boundingBox, mapBounds)
    );
    const projectsWithBoundingBox = visibleProjects.filter(
      (project) => project.boundingBox !== null
    );
    return projectsWithBoundingBox.map((project) => ({
      id: project.id,
      boundingBox: project.boundingBox!,
      boundingBoxDiagonalSizeInMeters: project.boundingBoxDiagonalSize,
      boundingBoxCenter: centerOfBoundingBox(project.boundingBox!),
      areasCount: project.areaCount ?? project.areas?.length,
    }));
  });

  const pixelsPerMeter = useSelector((state: SystemState) => {
    const mapState = state.projectTree.mapState;

    return mapState.visibleRegionDiagonalInPixels / mapState.boundingBoxDiagonalSize;
  });

  const projectsInfoWithPixelsDiagonalSize = projectsInfo.map((projectInfo) => ({
    ...projectInfo,
    boundingBoxDiagonalSizeInPixels: projectInfo.boundingBoxDiagonalSizeInMeters * pixelsPerMeter,
  }));

  return (
    <>
      <ProjectsSummaryAndCluster
        moveToBoundingBox={props.moveToBoundingBox}
        projectsToShowSummary={projectsInfoWithPixelsDiagonalSize
          .filter((projectInfo) => isProjectAsSummary(projectInfo.boundingBoxDiagonalSizeInPixels))
          .map((projectInfo) => ({
            boundingBox: projectInfo.boundingBox,
            areasCount: projectInfo.areasCount,
            boundingBoxCenter: projectInfo.boundingBoxCenter,
          }))}
      />
      {projectsInfoWithPixelsDiagonalSize
        .filter((projectInfo) => !isProjectAsSummary(projectInfo.boundingBoxDiagonalSizeInPixels))
        .map((projectInfo) => (
          <ProjectAreasSummaryAndCluster
            projectId={projectInfo.id}
            pixelsPerMeter={pixelsPerMeter}
            moveToBoundingBox={props.moveToBoundingBox}
          />
        ))}
    </>
  );
};

const ProjectsSummaryAndCluster = (props: {
  moveToBoundingBox: (boundingBox: BoundingBox) => void;
  projectsToShowSummary: {
    boundingBoxCenter: Location;
    boundingBox: BoundingBox;
    areasCount: number | undefined;
  }[];
}): JSX.Element => {
  const mapBounds = useSelector((state: SystemState) => state.projectTree.mapState.bounds);
  const mapZoom = useSelector((state: SystemState) => state.projectTree.mapState.zoom);

  return (
    <Cluster
      mapBounds={mapBounds}
      mapZoom={mapZoom}
      clusterIcon={
        new L.Icon({
          iconUrl: "/marker_cluster_pink.png",
          iconSize: [projectsClusterSize, projectsClusterSize],
        })
      }
      clusterRadios={projectsClusterSize}
      clusterZIndex={2}
      items={props.projectsToShowSummary.map((projectInfo) => ({
        center: projectInfo.boundingBoxCenter,
        boundingBox: projectInfo.boundingBox,
        child: (
          <Marker
            position={projectInfo.boundingBoxCenter}
            icon={
              new L.Icon({
                iconUrl: "/marker_cluster_red.png",
                iconSize: [projectSummarySize, projectSummarySize],
              })
            }
            onClick={() => props.moveToBoundingBox(projectInfo.boundingBox)}
            zIndex={2}
          >
            <Tooltip direction="center" offset={[0, 0]} opacity={1} permanent>
              <div
                style={{
                  color: "black",
                  verticalAlign: "middle",
                  display: "inline-block",
                  textAlign: "center",
                  fontWeight: "bold",
                }}
              >
                {projectInfo.areasCount}
              </div>
            </Tooltip>
          </Marker>
        ),
      }))}
      moveToBoundingBox={props.moveToBoundingBox}
    />
  );
};

const getAreaBoundingBox = (
  selectedProjectId: string | null,
  selectedAreaId: string | null | undefined,
  area: AreaInProjectTree
): {
  bounds: BoundingBox;
  diagonalSizeInMeters: number;
} => {
  if (area.id === selectedAreaId && area.projectId === selectedProjectId) {
    return {
      bounds: area.viewingFlightPlan?.boundingBox ?? area.boundingBox,
      diagonalSizeInMeters:
        area.viewingFlightPlan?.boundingBoxDiagonalSize ?? area.boundingBoxDiagonalSize,
    };
  }

  return {
    bounds: area.boundingBox,
    diagonalSizeInMeters: area.boundingBoxDiagonalSize,
  };
};

const ProjectAreasSummaryAndCluster = (props: {
  projectId: string;
  pixelsPerMeter: number;
  moveToBoundingBox: (boundingBox: BoundingBox) => void;
}): JSX.Element => {
  const areasInfo = useSelector((state: SystemState) => {
    const projectTreeState = state.projectTree;
    const selectedProjectId = projectTreeState.selectedProjectId;
    const project = projectTreeState.projectList?.find((project) => project.id === props.projectId);
    const selectedAreaId = project?.selectedAreaId;
    const areaList = project?.areas ?? [];

    const mapBounds = projectTreeState.mapState.bounds;

    const areasWithViewingFlightPlanBounds = areaList.map((area) => {
      const areaBounds = getAreaBoundingBox(selectedProjectId, selectedAreaId, area);

      return {
        ...area,
        boundingBox: areaBounds.bounds,
        boundingBoxDiagonalSize: areaBounds.diagonalSizeInMeters,
      };
    });

    const visibleAreas = areasWithViewingFlightPlanBounds.filter(
      (area) =>
        area.visible && couldMapElementBeVisibleAccordingMapBounds(area.boundingBox, mapBounds)
    );
    return visibleAreas.map((area) => ({
      boundingBox: area.boundingBox,
      boundingBoxDiagonalSizeInMeter: area.boundingBoxDiagonalSize,
      boundingBoxCenter: centerOfBoundingBox(area.boundingBox),
      waypointsCounter: area.planned.route?.waypoints?.length,
    }));
  });

  return (
    <AreasSummaryAndCluster
      areasToShowSummary={areasInfo.filter((areaInfo) =>
        isAreaAsSummary(areaInfo.boundingBoxDiagonalSizeInMeter * props.pixelsPerMeter)
      )}
      moveToBoundingBox={props.moveToBoundingBox}
    />
  );
};

const AreasSummaryAndCluster = (props: {
  moveToBoundingBox: (boundingBox: BoundingBox) => void;
  areasToShowSummary: {
    boundingBoxCenter: Location;
    boundingBox: BoundingBox;
    waypointsCounter: number | undefined;
  }[];
}): JSX.Element => {
  const mapBounds = useSelector((state: SystemState) => state.projectTree.mapState.bounds);
  const mapZoom = useSelector((state: SystemState) => state.projectTree.mapState.zoom);

  return (
    <Cluster
      mapBounds={mapBounds}
      mapZoom={mapZoom}
      clusterIcon={
        new L.Icon({
          iconUrl: "/marker_cluster_yellow.png",
          iconSize: [areasClusterSize, areasClusterSize],
        })
      }
      clusterRadios={areasClusterSize}
      clusterZIndex={1}
      items={props.areasToShowSummary.map((areaInfo) => ({
        center: areaInfo.boundingBoxCenter,
        boundingBox: areaInfo.boundingBox,
        child: (
          <Marker
            position={areaInfo.boundingBoxCenter}
            icon={
              new L.Icon({
                iconUrl: "/marker_cluster_blue.png",
                iconSize: [areaSummarySize, areaSummarySize],
              })
            }
            zIndex={1}
            onClick={() => props.moveToBoundingBox(areaInfo.boundingBox)}
          >
            <Tooltip direction="center" offset={[0, 0]} opacity={1} permanent>
              <div
                style={{
                  color: "black",
                  verticalAlign: "middle",
                  display: "inline-block",
                  textAlign: "center",
                  fontWeight: "bold",
                }}
              >
                {areaInfo.waypointsCounter}
              </div>
            </Tooltip>
          </Marker>
        ),
      }))}
      moveToBoundingBox={props.moveToBoundingBox}
    />
  );
};
