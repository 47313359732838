import { Location } from "biohub-model";
import React from "react";
import { Polygon } from "react-leaflet";

export default (props: {
  polygon: Location[];
  isEditing?: boolean;
  onClick?: (location: Location) => void;
}): JSX.Element => {
  return (
    <Polygon
      positions={props.polygon}
      pathOptions={{
        color: props.isEditing === true ? "transparent" : "rgba(128, 222, 234, 102)",
        fillColor: "rgba(128, 222, 234, 102)",
      }}
      eventHandlers={{
        click: (e: L.LeafletMouseEvent) => {
          const position = e.latlng;
          const location: Location = {
            lat: position.lat,
            lng: position.lng,
          };

          props.onClick?.(location);
        },
      }}
    />
  );
};
