import { Flight, ReleaserAction, Waypoint } from "biohub-model";
import React from "react";
import FlightRoute from "../components/flight_route";
import FlightReleaseTracks from "../components/flight_release_tracks";
import OpenedAreaPolygon from "../components/opened_area_polygon";
import PlannedAreaRoute from "../components/planned_area_route";
import WaypointComponent from "../components/waypoint";
import L from "leaflet";

export default (props: {
  flight: Flight;
  mode: "plan" | "flight";
  map: L.Map;
  mapRotation: number;
}): JSX.Element => {
  return (
    <>
      <OpenedAreaPolygon polygon={props.flight.flightEnvironmentSnapshot.plannedArea.polygon} />
      <PlannedAreaRoute
        mapScaleSource="map"
        waypointsLocations={props.flight.flightEnvironmentSnapshot.plannedArea.route.waypoints.map(
          (waypoint) => waypoint.location
        )}
        map={props.map}
      />
      <FlightWaypoints flight={props.flight} map={props.map} mapRotation={props.mapRotation} />
      {props.mode === "flight" && (
        <>
          <FlightRoute flowRoute={props.flight.realData?.completeRoute ?? []} />
          <FlightReleaseTracks
            mapScaleSource="map"
            trackWidth={props.flight.flightEnvironmentSnapshot.trackWidth}
            flightReleaseSegments={
              props.flight.realData?.logData.map((log) => log.releaseRoute) ?? []
            }
          />
        </>
      )}
    </>
  );
};

const FlightWaypoints = (props: {
  flight: Flight;
  map: L.Map;
  mapRotation: number;
}): JSX.Element => {
  const homePointElevation = props.flight.flightEnvironmentSnapshot.plannedArea.homePointElevation;
  const waypoints: (Waypoint & { activeLiberation: boolean })[] = [];

  let activeLiberation = false;

  const flightWaypoints = props.flight.flightEnvironmentSnapshot.plannedArea.route.waypoints;

  for (let i = 0; i < flightWaypoints.length; i++) {
    const waypoint = flightWaypoints[i];

    let hasAnyReleasingChange = false;

    for (const [key, value] of Object.entries(waypoint.releaserActions)) {
      if (!hasAnyReleasingChange) {
        if (!activeLiberation && value.type === ReleaserAction.release) {
          hasAnyReleasingChange = true;
          activeLiberation = true;
        } else if (activeLiberation && value.type === ReleaserAction.stopRelease) {
          hasAnyReleasingChange = true;
          activeLiberation = false;
        }
      }
    }

    waypoints.push({
      ...waypoint,
      activeLiberation: activeLiberation,
    });
  }

  return (
    <>
      {waypoints.map((waypoint, index) => (
        <WaypointComponent
          mapScaleSource="map"
          isActiveLiberation={waypoint.activeLiberation}
          isSelected={false}
          waypointIndex={index}
          waypointLocation={waypoint.location}
          waypointHeading={waypoint.orientation}
          waypointHeight={waypoint.height}
          homePointElevation={homePointElevation}
          waypointElevation={waypoint.elevation}
          mapRotation={props.mapRotation}
          map={props.map}
        />
      ))}
    </>
  );
};
